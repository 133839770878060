import React, { useEffect, useState } from "react"
import TopRankedColleges from "../../../Components/Modals/Student/topranked-colleges"
import { useLocation } from "react-router-dom"
import { addExploreFeeData, getAllParentCourse, getAllSubCourse, getCountries, viewData } from "../../../services/career/career-service"
import { Accordion } from "react-bootstrap"

const FeeCalculator = () => {
  const [showColleges, setShowColleges] = useState(false)
  const [exploreFeeData, setExploreFeeData] = useState([])
  const [countriesData, setCountriesData] = useState([])
  const [courseData, setCourseData] = useState([])
  const [viewFeesData, setViewFeesData] = useState([])
  const [currency, setCurrency] = useState()
  console.log(currency)
  const [countryName, setCountryName] = useState()
  const [searchCountryQuery, setSearchCountryQuery] = useState("")
  const [searchSubCourseQuery, setSearchSubCourseQuery] = useState("")
  const [collegeLevel, setCollegeLevel] = useState("Top Level Colleges")

  const [filter, setFilter] = useState({
    country_ids: new URLSearchParams(useLocation().search).get("country_ids").split(","),
    course_id: new URLSearchParams(useLocation().search).get("course_id"),
  })
  const [countries, setCountries] = useState(new URLSearchParams(useLocation().search).get("country_ids"))
  const [course, setCourse] = useState(new URLSearchParams(useLocation().search).get("course_id"))
  const exploreFees = async () => {
    // if (filter.country_ids?.length > 0 && filter.course_id) {
    // let payload = {
    //   country_ids: country,
    //   course_id: parseInt(course),
    // };
    const res = await addExploreFeeData(filter)
    if (res && res?.data?.data?.length) {
      setExploreFeeData(res?.data?.data)
    } else {
      setExploreFeeData([])
    }
    // }
  }
  const viewCollegeData = async (countryId, collegeType) => {
    let payload = {
      country_id: countryId,
      college_level: collegeType,
    }
    setCollegeLevel(collegeType)
    await viewData(payload).then((res) => {
      if (res?.data?.data) {
        setViewFeesData(res?.data?.data)
      }
    })
  }
  useEffect(() => {
    if (countries && course) {
      exploreFees()
      getAllCourse()
      getAllCountries()
    }
  }, [countries, course])
  const getAllCountries = async () => {
    let payload = {
      search: searchCountryQuery,
    }
    await getCountries(payload).then((res) => {
      if (res.data?.data?.records) {
        setCountriesData(res.data?.data?.records)
      }
    })
  }
  useEffect(() => {
    getAllCountries()
  }, [searchCountryQuery])
  useEffect(() => {
    exploreFees()
  }, [countriesData, courseData])
  const getAllCourse = async () => {
    await getAllParentCourse({ search: searchSubCourseQuery }).then((res) => {
      if (res.data?.data) {
        let getCource = res.data?.data?.map((x) => {
          if (course == x.id) {
            x["isChecked"] = true
          } else {
            x["isChecked"] = false
          }
          return x
        })
        setCourseData(getCource)
      }
    })
  }
  useEffect(() => {
    getAllCourse()
  }, [searchSubCourseQuery, filter])
  return (
    <>
      <div className="main flex-1" style={{ height: "100vh" }}>
        <div className="max-1140 mx-auto d-flex h-100">
          <div className="flex-1">
            <div className="mt-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="h-100 p-0">
                    <div className="m-w-90 d-flex flex-column justify-content-between mt-3 mb-3">
                      <h3 className="font-32 font-600 mb-1 block-title aos-init aos-animate">Course Fee Calculator</h3>
                      <p className="font-16 font-400 mb-2">Get a quick estimate of budgets in various countries for different courses and colleges</p>
                    </div>

                    <div className="row JobInternshipListing">
                      <div
                        className="col-lg-3 col-sm-12 ji_filtersec aos-init aos-animate"
                        style={{
                          height: "85vh",
                          overflow: "scroll",
                        }}
                      >
                        <div className="mobileFilter mobileonly text-end">
                          <button className="btn-filter btn-unscroll font-18 font-500" type="button">
                            <span className="icon-filter"></span>
                            Filters
                          </button>
                        </div>
                        <div className="filters leftPaneFilter collapse show" id="filter_collapse">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <button className="btn-filter btn-unscroll mobileonly" type="button">
                                Filters
                              </button>
                              <h3 className="font-18 font-500 mb-2 mt-2 aos-init aos-animate">
                                <span className="icon-filter"></span>
                                Filters
                              </h3>
                            </div>
                            <span
                              className="font-14 font-500 text-burgandi text-decoration-underline cursor-pointer"
                              onClick={() => {
                                let countryFilter = Object.assign([], countriesData)
                                countryFilter = countryFilter.map((t) => {
                                  t["isChecked"] = false
                                  return t
                                })
                                let departmentFilter = Object.assign([], courseData)
                                departmentFilter = departmentFilter?.map((t) => {
                                  t["isChecked"] = false
                                  return t
                                })
                                setFilter({
                                  country_ids: undefined,
                                  course_id: undefined,
                                })
                                // console.log(countryFilter);
                                setCountriesData(countryFilter)
                                setCourseData(departmentFilter)
                              }}
                            >
                              Clear
                            </span>
                          </div>

                          <div className="college-filter-box">
                            <Accordion alwaysOpen defaultActiveKey={["0"]} className="filterGroup">
                              <Accordion.Item eventKey="0" className="fiterItem mb-3">
                                <Accordion.Header className="filterHeader p-0">
                                  <div className="d-flex justify-content-between align-items-center position-relative" style={{ width: "100%" }}>
                                    <button className="btn-nostyle text-start flex-1 p-0 d-flex justify-content-between align-items-center" type="button">
                                      <span className="font-18 font-600">Country</span>
                                      <span className="toggle"></span>
                                    </button>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body className="p-0" id="collapseDepartment">
                                  <div className="filterSearchblk mb-3">
                                    <form className="d-flex align-items-center filterSearch position-relative">
                                      <input className="form-control flex-1 font-14 font-500 w-100" type="search" placeholder="Search" value={searchCountryQuery} onChange={(e) => setSearchCountryQuery(e.target.value)} />
                                      <button className="btn icon-search" type="submit"></button>
                                    </form>
                                  </div>
                                  <div className="card card-body mt-2">
                                    <div className="overflow-hidden">
                                      <div className="scrollMe">
                                        {countriesData?.map((data, i) => {
                                          return (
                                            <div className="form-check d-flex flex-wrap" key={i}>
                                              <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                name="course"
                                                key={i}
                                                id={"Department_Architecture" + i}
                                                // value={data.id}
                                                checked={filter && filter?.country_ids?.includes(data.id.toString())}
                                                onChange={(e) => {
                                                  let temp = Object.assign([], filter.country_ids)
                                                  if (e.target.checked) {
                                                    temp.push(data.id.toString())
                                                  } else {
                                                    let ind = filter.country_ids.findIndex((y) => y.toString() === data.id.toString())

                                                    temp.splice(ind, 1)
                                                  }
                                                  setFilter({
                                                    country_ids: temp,
                                                    course_id: filter.course_id,
                                                  })
                                                }}
                                              />
                                              <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">{data?.name}</label>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="1" className="fiterItem mb-3">
                                <Accordion.Header className="filterHeader p-0">
                                  <div className="d-flex justify-content-between align-items-center position-relative" style={{ width: "100%" }}>
                                    <button className="btn-nostyle text-start flex-1 p-0 d-flex justify-content-between align-items-center" type="button">
                                      <span className="font-18 font-600">Department</span>
                                      <span className="toggle"></span>
                                    </button>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body className="p-0" id="collapseDepartment">
                                  <div className="filterSearchblk mb-3">
                                    <form className="d-flex align-items-center filterSearch position-relative">
                                      <input className="form-control flex-1 font-14 font-500 w-100" type="search" placeholder="Search" value={searchSubCourseQuery} onChange={(e) => setSearchSubCourseQuery(e.target.value)} />
                                      <button className="btn icon-search" type="submit"></button>
                                    </form>
                                  </div>
                                  <div className="card card-body mt-2">
                                    <div className="overflow-hidden">
                                      <div className="scrollMe">
                                        {courseData?.map((data, i) => {
                                          return (
                                            <div className="form-check d-flex flex-wrap" key={i}>
                                              <input
                                                className="form-check-input me-2"
                                                type="radio"
                                                name="course"
                                                key={i}
                                                id={"Department_Architecture" + i}
                                                checked={filter?.course_id == data.id.toString()}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    setFilter({
                                                      country_ids: filter.country_ids,
                                                      course_id: data.id,
                                                    })
                                                  } else {
                                                    setFilter({
                                                      country_ids: filter.country_ids,
                                                      course_id: undefined,
                                                    })
                                                  }
                                                }}
                                              />
                                              <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">{data?.name}</label>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-9 col-sm-12 rightrankSection polarcontsec tab-content" id="myTabContent">
                        <div className="ActiveListings_present">
                          <div className="row mt-0 college-details" id="accordionCollegeDetails">
                            <div
                              className="col-md-12"
                              style={{
                                height: "calc(100vh - 135px)",
                                overflow: "scroll",
                              }}
                            >
                              {exploreFeeData?.length ? (
                                exploreFeeData?.map((item, index) => {
                                  console.log(item?.currency_data?.unicode_character)
                                  return (
                                    <div className="countryRow mb-5" key={index}>
                                      <div className="countryTitle d-flex align-items-center mb-2">
                                        {/* <img
                                          src="/assets/images/australia-flag.png"
                                          width="45"
                                          alt="australia-flag"
                                        /> */}
                                        <span className="font-20 font-600 ms-3">{item?.country}</span>
                                      </div>
                                      <div className="scroll">
                                        <div className="d-flex flex-wrap">
                                          {item?.fee?.map((x, index) => {
                                            return (
                                              <div
                                                className="listed-country border border-radius-10"
                                                style={{
                                                  height: "unset",
                                                  borderColor: "#dee2e6",
                                                  boxShadow: "0 0px 6px 0px #ccc",
                                                }}
                                                key={index}
                                              >
                                                <div className="feeDetails position-relative">
                                                  <div className="feeDetails-cover">
                                                    <div className="rank-text px-4 py-3">
                                                      <h3 className="font-16 font-500 m-0 position-relative">{x.institution_level}</h3>
                                                    </div>
                                                    <div className="feescalculatediv bg-white">
                                                      <Accordion className="filterGroup">
                                                        <Accordion.Item
                                                          eventKey="0"
                                                          className="fiterItem mb-3"
                                                          style={{
                                                            border: "unset",
                                                          }}
                                                        >
                                                          <Accordion.Header className="d-flex justify-content-between align-items-center position-relative">
                                                            <div className="btn-nostyle text-start flex-1 p-0  justify-content-between align-items-center">
                                                              <p className="text-blue font-12 font-600 mb-1">Avg Fees/Year</p>
                                                              <div className="price font-20 font-600">
                                                                <span
                                                                  dangerouslySetInnerHTML={{
                                                                    __html: item?.currency_data?.unicode_character,
                                                                  }}
                                                                ></span>{" "}
                                                                {parseInt(x?.average_fee).toFixed(0)}
                                                              </div>
                                                            </div>
                                                          </Accordion.Header>
                                                          <Accordion.Body
                                                            style={{
                                                              backgroundColor: " #f4f4f4",
                                                              paddingTop: "0",
                                                            }}
                                                          >
                                                            <div className="btn-nostyle text-start flex-1 p-0  justify-content-between align-items-center">
                                                              <p className="text-blue font-12 font-600 mb-1">Highest Fees</p>
                                                              <div className="price font-20 font-600">
                                                                <span
                                                                  dangerouslySetInnerHTML={{
                                                                    __html: item?.currency_data?.unicode_character,
                                                                  }}
                                                                ></span>{" "}
                                                                {x?.highest_fee}
                                                              </div>
                                                            </div>
                                                            <div className="btn-nostyle text-start flex-1 p-0  justify-content-between align-items-center">
                                                              <span className="text-blue font-12 font-600">Lowest Fees</span>
                                                              <div className="price font-20 font-600">
                                                                <span
                                                                  dangerouslySetInnerHTML={{
                                                                    __html: item?.currency_data?.unicode_character,
                                                                  }}
                                                                ></span>{" "}
                                                                {x?.lowest_fee}
                                                              </div>
                                                            </div>
                                                          </Accordion.Body>
                                                        </Accordion.Item>
                                                      </Accordion>
                                                      <div className="d-flex px-4 pb-3">
                                                        <button
                                                          type="button"
                                                          className="btn btn-completed btn-unscroll radius height-45 max-150 d-block w-100 font-14 font-500"
                                                          onClick={() => {
                                                            setShowColleges(true)
                                                            setCountryName(item?.country)
                                                            viewCollegeData(item?.country_id, x?.institution_level)
                                                            setCurrency(item?.currency_data?.unicode_character)
                                                          }}
                                                        >
                                                          <span>View Colleges</span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              ) : (
                                <h4 className="ms-2 mt-4"> No Records Found.</h4>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TopRankedColleges viewFeesData={viewFeesData} showColleges={showColleges} countryName={countryName} setShowColleges={setShowColleges} currency={currency} collegeLevel={collegeLevel} />
    </>
  )
}

export default FeeCalculator
