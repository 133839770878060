import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { getUserInfo, updateAdditionalInfoDetail } from "../../../../services/user-basicinfo/user-basicinfo"
import { toast } from "react-toastify"

const EditAdditionalInfo = () => {
  const navigate = useNavigate()

  const [charCount, setCharCount] = useState(0)
  const [charLimitExceeded, setCharLimitExceeded] = useState(false)

  const initialValues = {
    running_business_since: undefined,
    dob: undefined,
    professional_headline: undefined,
    office_address_line_1: undefined,
    office_address_line_2: undefined,
    pincode: undefined,
  }
  let validationSchema = Yup.object({
    running_business_since: Yup.string(),
    professional_headline: Yup.string(),
    office_address_line_1: Yup.string(),
    office_address_line_2: Yup.string(),
    pincode: Yup.string(),
  })

  const handleProfessionalHeadlineChange = (e) => {
    const value = e.target.value
    setCharCount(value.length)

    // Check if the character count exceeds the limit
    if (value.length > 255) {
      setCharLimitExceeded(true)
    } else {
      setCharLimitExceeded(false)
    }

    handleChange(e)
  }

  const getUserInformation = async () => {
    getUserInfo().then((res) => {
      if (res && res?.data?.data) {
        setFieldValue("running_business_since", new Date(res?.data?.data?.business_started_date).getFullYear().toString())
        setFieldValue("dob", new Date(res?.data?.data?.dob).toISOString()?.substring(0, 10))
        setFieldValue("professional_headline", res?.data?.data?.professional_headline)
        setFieldValue("office_address_line_1", res?.data?.data?.office_address?.line_1)
        setFieldValue("office_address_line_2", res?.data?.data?.office_address?.line_2)
        setFieldValue("pincode", res?.data?.data?.office_address?.pincode)
      }
    })
  }
  useEffect(() => {
    getUserInformation()
  }, [])
  const onSubmit = async () => {
    let payload = {
      running_business_since: values.running_business_since,
      dob: values?.dob,
      professional_headline: values.professional_headline,
      office_address_line_1: values?.office_address_line_1,
      office_address_line_2: values?.office_address_line_2,
      pincode: (values?.pincode).toString(),
    }
    await updateAdditionalInfoDetail(payload).then((res) => {
      const { status, message } = res?.data?.meta
      if (status) {
        toast.success(message)
        resetForm()
        navigate("/user/personal-info")
      }
    })
  }
  const { handleChange, handleSubmit, values, setFieldValue, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <React.Fragment>
      <div className="flex-1">
        <div className="d-flex justify-content-between align-items-start pt-4 mt-2 mb-4">
          <div className="welcome-title d-flex flex-wrap align-items-center">
            <p onClick={() => navigate("/user/personal-info")} className="btn-circle-back"></p>
            <h1 className="ms-3 m-0 text-start">Update Additional Information</h1>
          </div>
        </div>

        <div className="row sd_formsec bg-white m-0 mb-4">
          <div data-aos="fade-up" data-aos-delay="900" id="no-notification" className="d-flex flex-column justify-content-between align-items-center mx-auto max-630 pt-4 pt-sm-5 pb-5 px-0 aos-init aos-animate">
            <form className="lstCustForm w-100" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-30">
                  <label for="First Name" className="form-label m-0 mb-1 font-16 font-500 w-100">
                    Working with school since
                  </label>

                  <input type="text" className="form-control flex-1" placeholder="Ex: 2023" name="running_business_since" value={values.running_business_since} onChange={(e) => handleChange(e)} />
                </div>
                <div className="col-md-6 mb-30">
                  <label for="DateofBirth" className="form-label m-0 mb-1 font-16 font-500 w-100">
                    Date of Birth (optional)
                  </label>
                  <input type="date" className="form-control flex-1" id="DateofBirth" placeholder="dd-mm-yyyy" name="dob" value={values.dob} onChange={(e) => handleChange(e)} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-30">
                  <label for="yourProfessionalHeadline" className="form-label m-0 mb-1 font-16 font-500 w-100">
                    Your Professional Headline (optional)
                  </label>

                  <input type="text" className="form-control flex-1" id="yourProfessionalHeadline" placeholder="Ex: Pursuing B.Tech in Electronics Engg. at IIT Kharagpur" name="professional_headline" value={values.professional_headline} onChange={handleProfessionalHeadlineChange} />
                  {/* <small>{charCount}/255 characters</small> */}
                  {charLimitExceeded && <p className="text-danger">You have exceeded the 255 character limit!</p>}
                </div>
                <div className="col-md-12 mb-30">
                  <label for="officeAddress" className="form-label m-0 mb-1 font-16 font-500 w-100">
                    Office Address (Building/ Street Name)
                  </label>

                  <input type="text" className="form-control flex-1" id="officeAddress" placeholder="Ex: 503, Block - J, Knowledge Park-II" name="office_address_line_1" value={values.office_address_line_1} onChange={(e) => handleChange(e)} />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-30">
                  <label for="officeAddressLocality" className="form-label m-0 mb-1 font-16 font-500 w-100">
                    Office Address (Locality/ Area)
                  </label>
                  <input type="text" className="form-control flex-1" id="officeAddressLocality" placeholder="Ex: Kormangala, Bangalore" name="office_address_line_2" value={values.office_address_line_2} onChange={(e) => handleChange(e)} />
                </div>
                <div className="col-md-6 mb-30">
                  <label for="officePinCode" className="form-label m-0 mb-1 font-16 font-500 w-100">
                    Office Pin Code/Zip Code
                  </label>
                  <input type="text" className="form-control flex-1" id="officePinCode" placeholder="Ex: 500089" name="pincode" value={values.pincode} onChange={(e) => handleChange(e)} />
                </div>
              </div>

              <div className="row justify-content-end align-items-center">
                <div className="my-2">
                  <button type="submit" className="btn btn-green max-200 text-white mx-auto d-block height-50">
                    <span>Update</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditAdditionalInfo
