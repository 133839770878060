import React from "react"
import { Modal } from "react-bootstrap"

const TopRankedColleges = ({ showColleges, setShowColleges, viewFeesData, countryName, Data, currency, collegeLevel }) => {
  console.log(currency)
  return (
    <React.Fragment>
      <Modal centered={true} size="xl" show={showColleges} className="modal fade" onHide={() => setShowColleges(false)}>
        <div className="radius-20 overflow-hidden">
          <div className="modal-header p-4 bg-lighter-blue">
            <div className="modal-title d-flex align-items-center" id="coursesModalLabel">
              <div>
                <h3 className="font-16 font-500 my-1">
                  {/* {collegeData?.heading} */}
                  {countryName} - {collegeLevel}
                </h3>
              </div>
            </div>
            <button type="button" className="btn-close btn-unscroll" onClick={() => setShowColleges(false)}></button>
          </div>
          {Data ? (
            <>
              {Data?.map((x, index) => {
                return (
                  <>
                    <div className="modal-body p-4" key={index}>
                      <div className="row mb-4 pb-4 border-bottom">
                        <div className="col-xl-5 col-lg-12 col-md-12 mb-4 mb-xl-0" key={index}>
                          <div className="college d-flex flex-wrap align-items-center">
                            <div className="flex-1">
                              <h2 className="font-20 font-500 mb-0">{x?.uni_name}</h2>
                              <div className="badgesection d-flex flex-wrap">
                                <span className="badge font-500 pin px-3 py-2 font-13 bg-gray-f4 text-gray-41 me-2 mt-2 d-flex"></span>
                                <span className="badge font-500 medal px-3 py-2 font-13 bg-gray-f4 text-gray-41 mt-2 d-flex"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-12">
                          <div className="row align-items-center h-100">
                            <div className="col-6 d-flex justify-content-center align-items-center customborder">
                              <div className="text-end">
                                <h3 className="font-20 font-500 text-end m-0"></h3>
                                <p className="font-14 font-500 text-end m-0"></p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="text-end">
                                <h3 className="font-20 font-500 text-end text-orange m-0"></h3>
                                <p className="font-14 font-500 text-end m-0"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
                          {/* <button className="btn gray radius d-flex align-items-center justify-content-center hvr-float-shadow font-13 font-500 px-4 py-2 mx-auto mb-0 mt-4 mt-xl-0">
                         Add to Shortlist
                       </button> */}
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
            </>
          ) : (
            <>
              {viewFeesData?.map((x, i) => {
                console.log(x)
                return (
                  <>
                    <div className="modal-body p-4 pb-0" key={i}>
                      <div className="row pb-4 border-bottom">
                        <div className="col-xl-7 col-lg-12 col-md-12 mb-4 mb-xl-0">
                          <div className="college d-flex flex-wrap align-items-center">
                            <div class="collegeImg me-3">
                              <img src={x.logo} class="img-fluid" alt="Monash University" width="90" />
                            </div>

                            <div className="flex-1">
                              <h2 className="font-20 font-500 mb-0">{x?.college_name}</h2>
                              <div className="badgesection d-flex flex-wrap">
                                <span className="badge font-500 pin px-3 py-2 font-13 bg-gray-f4 text-gray-41 me-2 mt-2 d-flex">
                                  {x?.city},{x?.state},{x?.country}{" "}
                                </span>
                                {x?.rank && <span className="badge font-500 medal px-3 py-2 font-13 bg-gray-f4 text-gray-41 mt-2 d-flex">{x?.rank}</span>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12">
                          <div className="row justify-content-center align-items-center h-100">
                            <div className="col-6 d-flex justify-content-center align-items-center customborder">
                              <div className="text-end">
                                <h3 className="font-20 font-500 text-end m-0">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: currency,
                                    }}
                                  ></span>
                                  {x?.average_fee ? parseInt(x?.average_fee).toFixed(2) : "-"}{" "}
                                </h3>
                                <p className="font-14 font-500 text-end m-0">Avg Fees/Year</p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="text-end">
                                {/* {x?.acceptance_ratio <= 30 ? <h3 className="font-20 font-500 text-end text-black m-0">{x?.acceptance_ratio && ">" +x?.acceptance_ratio +"%"}</h3> : (30 <= x?.acceptance_ratio <= 80) ? <h3 className="font-20 font-500 text-end text-orange m-0">{x?.acceptance_ratio && ">" +x?.acceptance_ratio +"%"}</h3> :<h3 className="font-20 font-500 text-end text-[#5cae48] m-0">{x?.acceptance_ratio && ">" +x?.acceptance_ratio +"%"}</h3>   } */}
                                <h3 className="font-20 font-500 text-end text-orange m-0">{x?.acceptance_ratio && ">" + x?.acceptance_ratio + "%"}</h3>
                                <p className="font-14 font-500 text-end m-0"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
                          {/* <button className="btn gray radius d-flex align-items-center justify-content-center hvr-float-shadow font-13 font-500 px-4 py-2 mx-auto mb-0 mt-4 mt-xl-0">
                            Add to Shortlist
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
            </>
          )}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default TopRankedColleges
